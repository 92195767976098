<template>
  <v-dialog v-model="show" width="300">
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Can't Enable.</v-toolbar-title>
      </v-toolbar>
      <br />
      <v-card-text>
        <div>Bot is expired.</div>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "expiredBotDialog",
  props: ["dialogStatus"],
  data: () => {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    show: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>

<style scoped>
</style>