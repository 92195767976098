<template>
  <v-dialog v-model="show" width="450" class="bg-white" persistent>
    <v-card color="white">
      <div>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/binance.png" width="25" height="25" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              ><span class="text-bold-black"
                >Link your new keys to this bot.</span
              ></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </div>
      <div class="px-4">
        <div>
          <span class="text-bold-black">API Key</span>.
          <v-text-field
            color="info"
            outlined
            light
            dense
            v-model="apiKeyField"
            append-icon="mdi-qrcode-scan"
            @click:append="uploadQRAction"
            required
            @input="$v.apiKeyField.$touch()"
            @blur="$v.apiKeyField.$touch()"
            :error-messages="apiKeyFieldErrors"
            :disabled="formDisabled"
          ></v-text-field>
        </div>
        <div>
          <span class="text-bold-black">Secret Key</span>.

          <v-text-field
            color="info"
            outlined
            light
            dense
            v-model="apiSecretKeyField"
            type="password"
            required
            @input="$v.apiSecretKeyField.$touch()"
            @blur="$v.apiSecretKeyField.$touch()"
            :error-messages="apiSecretKeyFieldErrors"
            :disabled="formDisabled"
          ></v-text-field>
        </div>
      </div>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="formDisabled"
          color="#EFF1FF"
          elevation="0"
          @click="show = false"
          light
          class="btn-cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="formDisabled"
          :loading="formDisabled"
          color="#4E8AFC"
          elevation="0"
          @click="updateKeyAction"
          light
          class="btn-update"
        >
          Update
        </v-btn>
      </v-card-actions>
      <qrcode-capture ref="file" @detect="onDetect" style="display: none" />
      <binanceDialog
        :dialogStatus.sync="errorDialog"
        :error_code.sync="error_code"
        @close="errorDialog = false"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { QrcodeCapture } from "vue-qrcode-reader";
import { checkBinanceKey } from "@/services/api/robot";
import binanceDialog from "@/components/binanace-dialog/errorDialog.vue";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
export default {
  name: "expiredBotDialog",
  props: ["dialogStatus"],
  mixins: [validationMixin],
  components: {
    QrcodeCapture,
    binanceDialog,
  },
  validations: {
    apiKeyField: { required },
    apiSecretKeyField: { required },
  },
  data: () => {
    return {
      apiKeyField: "",
      apiSecretKeyField: "",
      id: "",
      errorDialog: false,
      error_code: "",
      valid: true,
      formDisabled: false,
    };
  },
  mounted() {},
  methods: {
    confirmDisable() {
      this.$emit("confirm");
    },
    clearData() {
      this.apiKeyField = "";
      this.apiSecretKeyField = "";
      this.id = null;
    },
    uploadQRAction() {
      this.$refs.file.$el.click();
    },
    async onDetect(promise) {
      try {
        const {
          //   imageData, // raw image data of image/frame
          content, // decoded String or null
          //   location, // QR code coordinates or null
        } = await promise;

        if (content === null) {
          // decoded nothing
          this.apiKeyField = "";
          this.apiSecretKeyField = "";
        } else {
          let decodeResult = JSON.parse(content);
          this.apiKeyField = decodeResult.apiKey;
          this.apiSecretKeyField = decodeResult.secretKey;
          this.checkBinanceKeyAction();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkBinanceKeyAction() {
      this.formDisabled = true;
      let validBinance = await checkBinanceKey(
        this.apiKeyField,
        this.apiSecretKeyField
      );
      if (!validBinance.verified) {
        this.error_code = validBinance.description;
        this.errorDialog = true;
      }
      this.formDisabled = false;
    },
    async updateKeyAction() {
      if (!this.$v.$invalid) {
        this.formDisabled = true;
        let validBinance = await checkBinanceKey(
          this.apiKeyField,
          this.apiSecretKeyField
        );
        if (validBinance.verified) {
          this.$emit(
            "changeBinanceKey",
            this.apiKeyField,
            this.apiSecretKeyField
          );
        } else {
          this.error_code = validBinance.description;
          this.errorDialog = true;
        }
        this.formDisabled = false;
      } else {
        this.$v.$touch();
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    apiKeyFieldErrors() {
      const errors = [];
      if (!this.$v.apiKeyField.$dirty) return errors;
      !this.$v.apiKeyField.required && errors.push("Api Key is required.");
      return errors;
    },
    apiSecretKeyFieldErrors() {
      const errors = [];
      if (!this.$v.apiSecretKeyField.$dirty) return errors;
      !this.$v.apiSecretKeyField.required &&
        errors.push("Secret Key is required.");
      return errors;
    },
  },
  watch: {
    dialogStatus(visible) {
      if (visible) {
        this.apiKeyField = "";
        this.apiSecretKeyField = "";
        this.$v.$reset();
      }
    },
  },
};
</script>

<style scoped>
.bg-white {
  background-color: white;
}
.text-bold-black {
  color: black;
  font-weight: bold;
}
.btn-cancel {
  color: #4e8afc;
}
.btn-update {
  color: #eff1ff;
}
</style>