<template>
  <v-dialog v-model="show" width="300" persistent>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Recommendation</v-toolbar-title>
      </v-toolbar>
      <br />
      <v-card-text>
        <div class="description">
          We propose a minimum total balance of
          <b> {{ portfolioBalance }} USD. </b> The mony management method will
          fail if the total balance goes below
          <b> {{ portfolioBalance }} USD </b>
        </div>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false"> OK </v-btn>
        <!-- <v-btn text @click="confirmDisable"> Disable </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "expiredBotDialog",
  props: ["dialogStatus", "portfolioBalance"],
  data: () => {
    return {};
  },
  mounted() {},
  methods: {
    confirmDisable() {
      this.$emit("confirm");
    },
  },
  computed: {
    show: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>

<style scoped>
.description {
  /* color: #4B5A6B; */
  color: #fff;
}
.money {
  color: #e9f3f9;
}
</style>