<template>
  <v-container class="cryptomation-container">
    <v-row dense>
      <v-col cols="12">
        <h1>{{ $t("robotDetail.MySubscriptions") }}</h1>
      </v-col>
    </v-row>
    <v-row dense v-show="!mainLoading">
      <v-col
        cols="12"
        md="12"
        lg="6"
        xl="6"
        v-for="(item, index) in rentList"
        :key="item.id"
      >
        <v-card
          color="#1D3149"
          :ripple="false"
          v-on:click="openMySubscriptionDetail(index)"
        >
          <div class="d-flex flex-no-wrap">
            <v-avatar class="ma-3" size="125" tile>
              <v-img :src="item.image"></v-img>
            </v-avatar>
            <div>
              <v-card-text class="py-3">
                <div class="text-subtitle-1 text-sm-h6 text--primary">
                  {{ $changeLang(item.title) }}
                </div>

                <div class="text--primary py-1">
                  <span class="bot-title">
                    {{ $t("robotDetail.status") }}:
                  </span>
                  <span :class="item.status.cssClass">
                    {{ $changeLang(item.status.text) }}</span
                  >
                </div>
                <div class="text--primary pb-1">
                  <span class="bot-title">
                    {{ $t("robotDetail.expDate") }}:
                  </span>
                  {{ item.expireDate }}
                </div>
                <div class="text--primary pb-1">
                  <span class="bot-title"
                    >{{ $t("robotDetail.leverage") }}: </span
                  >{{ item.leverage }}x
                </div>
                <div class="text--primary">
                  <span class="bot-title">{{ $t("main.autoRenew") }}: </span>
                  <span v-if="item.autoRenew.color == 'red'" class="red--text">
                    {{ $changeLang(item.autoRenew.text) }}
                  </span>
                  <span
                    v-if="item.autoRenew.color == 'green'"
                    class="green--text"
                  >
                    {{ $changeLang(item.autoRenew.text) }}
                  </span>
                </div>
                <div class="text--primary status-container mt-2">
                  <div class="d-flex">
                    <span class="subtitle-1 status-label">
                      <span class="subtitle-1" v-if="item.active">
                        {{ $t("robotDetail.enable") }}
                      </span>
                      <span class="subtitle-1" v-else>
                        {{ $t("robotDetail.disable") }}
                      </span>
                    </span>
                    <v-switch
                      class="mt-0 status-btn"
                      v-model="item.active"
                      label=""
                      color="success"
                      hide-details
                      @change="(status) => changeStatus(index, status)"
                      @click.stop="dialog_sub_detail !== dialog_sub_detail"
                      width="30px"
                    ></v-switch>
                  </div>
                </div>
              </v-card-text>
            </div>
          </div>
          <!-- -->
        </v-card>
      </v-col>
    </v-row>
    <v-row dense v-show="mainLoading">
      <v-col>
        <MainLoading />
      </v-col>
    </v-row>
    <v-row dense v-show="!mainLoading && rentList.length === 0">
      <v-col>
        <v-card class="pa-5 d-flex flex-column justify-center" width="100%">
          <div class="pb-5 text-center">
            <v-icon size="60"> mdi-robot </v-icon>
          </div>

          <p class="headline text-center">Welcome to Crypto-Mation.</p>
        </v-card>
      </v-col>
    </v-row>
    <MySubscriptionsDetail
      :dialogStatus="dialog_sub_detail"
      :data.sync="selected_sub_detail"
      @close="dialog_sub_detail = false"
      @getRentList="getRentList"
    />
    <binanceDialog
      :dialogStatus.sync="updateFail"
      :error_code.sync="updateFailMessage"
      @close="updateFail = false"
    />
    <confirmDisableDialog
      :dialogStatus.sync="confirmDisableStatus"
      @close="confirmDisableStatus = false"
      @confirm="confirmDisable"
    />
  </v-container>
</template>

<script>
import { getRentAPI } from "@/services/api/user";
import { getLocalTimeFromTimestampDMY } from "@/utils/customDate";
import MySubscriptionsDetail from "./dialog/MySubscriptionsDetail.vue";
import { updateRentAPI } from "@/services/api/user";
import confirmDisableDialog from "./dialog/confirmDisable.vue";
import MainLoading from "../subcomponents/mainLoading.vue";
import binanceDialog from "@/components/binanace-dialog/errorDialog.vue";
// import { mapState } from "vuex";

export default {
  name: "MySubscriptions",
  components: {
    MySubscriptionsDetail,
    confirmDisableDialog,
    MainLoading,
    binanceDialog,
  },
  data: () => ({
    rentList: [],
    dialog_sub_detail: false,
    selected_sub_detail: {},
    updateFail: false,
    updateFailMessage: "",
    confirmDisableStatus: false,
    selectedRentIndex: null,
    mainLoading: true,
  }),
  mounted() {
    this.getRentList();
  },
  methods: {
    openMySubscriptionDetail(index) {
      this.selected_sub_detail = this.rentList[index];
      this.selectedRentIndex = index;
      this.dialog_sub_detail = true;
    },
    async getRentList() {
      let _rentList = await getRentAPI();
      // preprocess
      for (let i = 0; i < _rentList.length; i++) {
        _rentList[i]["expireDate"] = getLocalTimeFromTimestampDMY(
          _rentList[i]["expireTimestamp"]
        );

        if (_rentList[i]["status"]["color"] == "red") {
          _rentList[i]["status"]["cssClass"] = "red--text";
        } else if (_rentList[i]["status"]["color"] == "green") {
          _rentList[i]["status"]["cssClass"] = "green--text";
        }
      }
      this.rentList = _rentList;
      if (this.selectedRentIndex !== null) {
        this.selected_sub_detail = this.rentList[this.selectedRentIndex];
      }
      this.mainLoading = false;
      //
    },
    goto() {
      //
      this.$router.push({
        path: "/tradingbot/",
      });
    },
    async changeStatus(index, active) {
      this.selectedRentIndex = index;
      if (!active) {
        this.confirmDisableStatus = true;
      } else {
        await this.updateRent({ id: this.rentList[index].id, active });
      }
    },
    async updateRent(data) {
      let result = await updateRentAPI(data);
      if (result.message != null) {
        this.rentList[this.selectedRentIndex].active =
          !this.rentList[this.selectedRentIndex].active;
        this.updateFail = true;
        this.updateFailMessage = result.message;
      } else {
        await this.getRentList();
      }
    },
    async confirmDisable() {
      this.confirmDisableStatus = false;
      let id = this.rentList[this.selectedRentIndex].id;
      await this.updateRent({ id, active: false });
    },
  },
};
</script>
<style scoped>
.status-btn {
  margin-left: 20px;
}
.bot-title {
  color: #9caab0;
}
</style>
