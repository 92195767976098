<template>
  <v-dialog v-model="show" fullscreen>
    <v-card height="98%" color="#172033">
      <v-toolbar>
        <v-toolbar-title> {{ $t("tradingHistory.history") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn
          class="mx-auto mt-1"
          color="info"
          @click="setTradingHistoryDatePickerDialogAction()"
        >
          Open Filter
        </v-btn> -->
        <v-btn
          class="mx-auto mt-1"
          color="info"
          @click="getData(null, null)"
          icon
        >
          <v-icon color="white">mdi-refresh</v-icon></v-btn
        >
        <v-btn
          class="mx-auto mt-1"
          color="info"
          @click="setTradingHistoryDatePickerDialogAction()"
          icon
        >
          <v-icon color="white">mdi-filter</v-icon></v-btn
        >

        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="d-flex flex-column" v-if="!isLoading">
        <v-list color="#172033" subheader two-line>
          <v-subheader class="d-flex justify-space-between">
            <span class="font-weight-black">{{
              $t("tradingHistory.TotalProfit")
            }}</span>
            <span :class="reportItems.profile_class">
              {{ reportItems.profile_text }}
            </span>
          </v-subheader>
          <v-divider></v-divider>
          <template v-for="(item, index) in toShowHistoryItems">
            <v-list-item three-line v-bind:key="index">
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-space-between"
                  >{{ item.firstLeftText }}
                  <span :class="item.firstRightTextStyle">
                    {{ item.firstRightText }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="d-flex justify-space-between">
                  <span> {{ item.secondLeftText }} </span>
                  <span> {{ item.secondRightText }} </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="d-flex justify-space-between">
                  <span class="caption"> {{ item.thirdLeftText }} </span>
                  <span class="font-weight-black">
                    {{ item.thirdRightText }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="'vdi' + index"></v-divider>
          </template>
        </v-list>
      </v-sheet>
      <v-sheet v-if="isLoading">
        <MainLoading />
      </v-sheet>
      <v-sheet
        class="pl-4"
        color="#172033"
        v-if="!isLoading && toShowHistoryItems.length === 0"
      >
        <span class="caption">{{ errorMsg }}</span>
      </v-sheet>
    </v-card>
    <MyTradingHistoryDatePicker
      :dialogStatus.sync="tradingHistoryDatePickerDialogStatus"
      @close="tradingHistoryDatePickerDialogStatus = false"
      @getDateFromDatePicker="getDateFromDatePicker"
    />
    <!-- :startDateTime.sync="startUnixTime"
      :endDateTime.sync="endUnixTime" -->
  </v-dialog>
</template>

<script>
import { getTradingHistory } from "@/services/api/user";
import _ from "lodash";
import { getLocalTimeFromTimestampDMYHM } from "@/utils/customDate";
import MainLoading from "../../subcomponents/mainLoading.vue";
import MyTradingHistoryDatePicker from "./MyTradingHistoryDatePicker.vue";
import { getLocalUnixNow, getUnixTimeFromDate } from "@/utils/customDate";

export default {
  name: "MyTradingHistory",
  components: { MainLoading, MyTradingHistoryDatePicker },
  props: ["dialogStatus", "rentId"],
  computed: {
    show: {
      get() {
        if (this.dialogStatus) {
          this.getData(null, null);
        }
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data: () => {
    return {
      isLoading: true,
      errorMsg: "",
      reportItems: {},
      historyItems: [],
      toShowHistoryItems: [],
      currentUnixTime: null,
      startUnixTime: null,
      endUnixTime: null,
      //
      tradingHistoryDatePickerDialogStatus: false,
    };
  },
  methods: {
    processHistoryItems() {
      for (let i = 0; i < this.historyItems.length; i++) {
        this.historyItems[i]["show"] = 0; // not show row
        if (
          this.historyItems[i]["open"]["time"] !== null &&
          this.historyItems[i]["close"]["time"] !== null
        ) {
          //
          let _openTime = getLocalTimeFromTimestampDMYHM(
            this.historyItems[i]["open"]["time"]
          );
          let _closeTime = getLocalTimeFromTimestampDMYHM(
            this.historyItems[i]["close"]["time"]
          );
          this.historyItems[i]["show"] = 1; // show all
          this.historyItems[i]["firstLeftText"] = "Open -> Close";
          this.historyItems[i]["secondLeftText"] = `${this.historyItems[i][
            "open"
          ]["price"].toFixed(2)} -> ${this.historyItems[i]["close"][
            "price"
          ].toFixed(2)}`;
          this.historyItems[i][
            "thirdLeftText"
          ] = `${_openTime} -> ${_closeTime}`;
          //
          if (this.historyItems[i]["close"]["realizedPnl"] < 0) {
            this.historyItems[i]["firstRightTextStyle"] = "red--text";
          } else {
            this.historyItems[i]["firstRightTextStyle"] = "green--text";
          }

          this.historyItems[i]["firstRightText"] = `${this.historyItems[i][
            "close"
          ]["realizedPnl"].toFixed(2)} USDT`;

          this.historyItems[i][
            "secondRightText"
          ] = `VOL: ${this.historyItems[i]["open"]["qty"]}`;
          this.historyItems[i][
            "thirdRightText"
          ] = `${this.historyItems[i]["open"]["positionSide"]}`;

          this.toShowHistoryItems.push(_.clone(this.historyItems[i]));
        } else if (this.historyItems[i]["open"]["time"] !== null) {
          this.historyItems[i]["show"] = 2; // show open
          this.historyItems[i]["firstLeftText"] = "Open";
          this.historyItems[i]["secondLeftText"] = `${this.historyItems[i][
            "open"
          ]["price"].toFixed(2)}`;
          this.historyItems[i][
            "thirdLeftText"
          ] = `${this.historyItems[i]["open"]["timeStr"]}`;
          //
          this.historyItems[i]["firstRightText"] = "-";
          this.historyItems[i][
            "secondRightText"
          ] = `VOL: ${this.historyItems[i]["open"]["qty"]}`;
          this.historyItems[i][
            "thirdRightText"
          ] = `${this.historyItems[i]["open"]["positionSide"]}`;
          this.toShowHistoryItems.push(_.clone(this.historyItems[i]));
        }
        //
      }
    },
    async getData(_startTime, _endTime) {
      this.isLoading = true;
      this.reportItems = { profile_text: "-" };
      this.toShowHistoryItems = [];
      //
      this.currentUnixTime = getLocalUnixNow();
      //this.startUnixTime = this.currentUnixTime - 60 * 60 * 24 * 30 * 1000;
      //this.endUnixTime = this.currentUnixTime;
      //
      let res_data = await getTradingHistory(
        this.rentId,
        "BTCUSDT",
        _startTime,
        _endTime
      );
      // console.log(res_data);
      if (res_data["code"] == 200) {
        if (res_data["data"] !== null) {
          this.reportItems = _.clone(res_data["data"]["report"]);
          // process reportItem
          if (this.reportItems["profit"] > 0) {
            this.reportItems["profile_class"] = "green--text font-weight-black";
          } else {
            this.reportItems["profile_class"] = "red--text font-weight-black";
          }
          this.reportItems[
            "profile_text"
          ] = `$${this.reportItems["profit"]} USDT`;
          this.historyItems = _.clone(res_data["data"]["tradeList"]);
          this.processHistoryItems();
        }
        // check
        if (this.toShowHistoryItems.length == 0) {
          this.errorMsg = this.$t('tradingHistory.noTradingHistory');
        }
      } else {
        this.errorMsg = "Please Check Your Internet Connection.";
      }
      this.isLoading = false;
    },
    getDateFromDatePicker(value) {
      // get array
      if (value.length == 2) {
        let conv_data = [];
        for (let i = 0; i < value.length; i++) {
          conv_data.push(getUnixTimeFromDate(value[i]));
        }
        // add one day
        conv_data[1] += 24 * 60 * 60;
        // call api*******
        this.getData(conv_data[0], conv_data[1]);
      } else {
        // do later
        console.log("select fail");
      }
    },
    setTradingHistoryDatePickerDialogAction() {
      this.tradingHistoryDatePickerDialogStatus = true;
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
