import {
  authHttpClientGet,
  authHttpClientPut,
  authHttpClientPost,
} from "@/services/api/axios";
import { getLocalUnixNow } from "@/utils/customDate";
export async function getRentAPI() {
  try {
    let res_data = await authHttpClientGet("/rent/list");
    if (res_data["code"] == 200) {
      return res_data["data"];
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export async function updateRentAPI(data) {
  try {
    return await authHttpClientPut("/rent/detail", {
      ...data,
    });
  } catch (error) {
    return null;
  }
}

export async function changePasswordAPI(data) {
  try {
    return await authHttpClientPut("/user/password", {
      ...data,
    });
  } catch (error) {
    // console.log(error)
    return {
      error: true,
      code: error.code,
      message: error.data.message,
    };
  }
}

export async function requestVerificationEmail() {
  try {
    return await authHttpClientPost("/user/verification/request", {});
  } catch (error) {
    return null;
  }
}

export async function getTradingHistory(
  rentId,
  symbol,
  _startTime = null,
  _endTime = null
) {
  try {
    // get date
    // let day = 30;
    // let _startTime = getLocalUnixNow() - 2592000 //(60*60*24*day);
    // let _endTime = getLocalUnixNow();
    if (_startTime === null) {
      _endTime = getLocalUnixNow();
      _startTime = _endTime - 60 * 60 * 24 * 30 * 1000;
    }
    
    return await authHttpClientGet(
      `/rent/report?rentId=${rentId}&symbol=${symbol}&startTime=${_startTime}&endTime=${_endTime}`
    );
  } catch (error) {
    return {
      error: true,
      code: error.code,
      message: error.data.message,
    };
  }
}
